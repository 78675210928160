<template>
  <div>
    <b-modal
      v-model="modalOptionUpdate"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Disagree')"
      :ok-title="$t('Agree')"
      modal-class="modal-primary"
      centered
      :title="$t('Update {resource}', { resource: $t('Partner') })"
      @ok="chooseOptionLoadFile"
      @cancel="cancelLoadNewFile"
      @hidden="cancelLoadNewFile"
      @close="cancelLoadNewFile"
    >
      <b-card-text>
        <div>
          {{ $t('Update {resource}', { resource: $t('partner') }) }}
        </div>
        <div class="d-flex flex-row align-items-center mt-1">
          <span class="text--primary" style="white-space: nowrap">
            {{ $t('You want') }}:&nbsp;
          </span>
          <div class="ml-1">
            <b-form-radio v-model="loadingFileSelected" name="some-radios" value="remain">
              Giữ nguyên các cột không có trong file
            </b-form-radio>
            <b-form-radio v-model="loadingFileSelected" name="some-radios2" value="delete">
              <span class="text-danger">Xoá các cột không có trong file</span>
            </b-form-radio>
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <validation-observer ref="mappingForm">
      <form ref="mappingFormName" @submit.prevent="updatePartnerAndUpload">
        <!-- Table Container Card -->
        <b-card no-body class="mb-2">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                <b-container>
                  <validation-observer ref="uploadForm">
                    <form @submit.prevent="uploadFile">
                      <b-row align-v="end">
                        <b-col>
                          <validation-provider
                            v-slot="{ errors }"
                            name="file"
                            mode="eager"
                            rules="required"
                          >
                            <v-select
                              v-model="campaign"
                              label="name"
                              :reduce="(item) => item.id"
                              :options="campaignOptions"
                              placeholder="Chọn chiến dịch"
                              class="w-100"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col>
                          <validation-provider v-slot="{ errors }" name="file" mode="eager">
                            <b-form-file
                              id="fileUpload"
                              v-model="file"
                              drop-placeholder="Drop file here..."
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col>
                          <base-select
                            id="header-row-pos"
                            v-model="partnersValue"
                            :reduce="(item) => item.value"
                            :options="partners"
                            :clearable="false"
                            label="text"
                            rules="required"
                            placeholder="Chọn đối tác"
                          />
                        </b-col>
                        <b-col cols="2">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            type="submit"
                            :disabled="loading"
                          >
                            <b-spinner v-if="loading" small />
                            <span class="align-middle ml-50">Tải file</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </form>
                  </validation-observer>
                </b-container>
              </b-col>
            </b-row>
          </div>
        </b-card>

        <!-- Table Container Card -->
        <b-card v-if="mappingTemp && mappingTemp.length > 0" no-body class="mb-0">
          <div class="m-2">
            <b-tabs>
              <b-tab title="Các cột thêm mới" active>
                <div class="personal-infor-content">
                  <template v-if="newMapping && newMapping.length > 0">
                    <b-row class="text-center">
                      <b-col md="12" xl="12" class="mb-1">
                        <b-table-simple
                          id="table-mapping"
                          ref="refUserListTable2"
                          class="position-relative"
                        >
                          <b-thead>
                            <b-tr>
                              <b-th
                                v-for="(header, i) in tableHead"
                                :key="i"
                                :style="`
                            width: ${header.width}%;
                            text-align: ${header.align} !important;
                          `"
                              >
                                {{ header.text }}</b-th
                              >
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <template v-if="mappingLoading">
                              <b-tr>
                                <b-td colspan="100">
                                  <b-spinner
                                    variant="primary"
                                    style="width: 3rem; height: 3rem"
                                    class="mr-1"
                                    label="Large Spinner"
                                  />
                                </b-td>
                              </b-tr>
                            </template>
                            <template v-else>
                              <b-tr v-for="(aMap, i) in newMapping" :key="i">
                                <b-td style="padding-left: 20px !important">
                                  {{ i + 1 }}
                                </b-td>
                                <b-td class="text-left">
                                  <validation-provider
                                    v-slot="{ errors }"
                                    mode="eager"
                                    rules="requiredWithoutName"
                                  >
                                    <b-form-input v-model="aMap.header" size="small" />
                                    <small v-if="errors[0]" class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-td>
                                <b-td>
                                  <base-select
                                    v-model="aMap.type"
                                    :name="`mapping:${i}:type`"
                                    :options="types"
                                    :reduce="(item) => item.value"
                                    label="text"
                                    :clearable="false"
                                  />
                                </b-td>
                                <b-td>
                                  <base-select
                                    :name="`mapping:${i}:table`"
                                    :options="tableNamesItems"
                                    :reduce="(item) => item.value"
                                    :value="aMap.table"
                                    label="text"
                                    :clearable="false"
                                    @input="handleChangeTableName($event, i)"
                                  />
                                </b-td>
                                <b-td class="text-left">
                                  <select-modal
                                    v-if="columns[i] && columns[i].length > 0"
                                    :options="columns[i]"
                                    :value="aMap.column"
                                    :prefix-trans="newMapping[i].table"
                                    :title="
                                      $t('Please chose column from {resource}', {
                                        resource: $t(newMapping[i].table),
                                      })
                                    "
                                    @selected="handleChangeColumn($event, i)"
                                  />
                                  <validation-provider
                                    v-else
                                    v-slot="{ errors }"
                                    mode="eager"
                                    :vid="`mapping.${i}.label`"
                                    rules="requiredWithoutName"
                                  >
                                    <b-form-input v-model="aMap.label" placeholder="Tên đối tác" />
                                    <small v-if="errors[0]" class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-td>
                                <b-td>
                                  <b-form-checkbox
                                    :name="`mapping:${i}:require`"
                                    :checked="aMap.require"
                                    :value="1"
                                    :unchecked-value="0"
                                    class="custom-control-primary d-inline-block"
                                  />
                                </b-td>
                                <b-td>
                                  <base-button
                                    variant="flat-danger"
                                    class="btn-icon rounded-circle"
                                    @click="handleClickDeleteRow(i)"
                                  >
                                    <feather-icon size="20" icon="XIcon" />
                                  </base-button>
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td colspan="100">
                                  <base-button
                                    id="add-new-row"
                                    variant="outline-primary"
                                    @click="handleAddNewRow"
                                  >
                                    <feather-icon icon="PlusCircleIcon" class="mr-50" />
                                    <span class="align-middle"> {{ $t('Add new row') }}</span>
                                  </base-button>
                                </b-td>
                              </b-tr>
                            </template>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </template>
                </div>
              </b-tab>
              <b-tab title="Các cột sẽ xoá">
                <template v-if="deleteMapping && deleteMapping.length > 0">
                  <b-row class="text-center">
                    <b-col md="12" xl="12" class="mb-1">
                      <b-table-simple
                        id="table-mapping2"
                        ref="refUserListTable2"
                        class="position-relative"
                      >
                        <b-thead>
                          <b-tr>
                            <b-th
                              v-for="(header, i) in tableHead"
                              :key="i"
                              :style="`
                            width: ${header.width}%;
                            text-align: ${header.align} !important;
                          `"
                            >
                              {{ header.text }}</b-th
                            >
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <template v-if="mappingLoading">
                            <b-tr>
                              <b-td colspan="100">
                                <b-spinner
                                  variant="primary"
                                  style="width: 3rem; height: 3rem"
                                  class="mr-1"
                                  label="Large Spinner"
                                />
                              </b-td>
                            </b-tr>
                          </template>
                          <template v-else>
                            <b-tr v-for="(aMap, i) in deleteMapping" :key="i">
                              <b-td style="padding-left: 20px !important">
                                {{ i + 1 }}
                              </b-td>
                              <b-td class="text-left">
                                <b-form-input :value="aMap.header" size="small" disabled />
                              </b-td>
                              <b-td>
                                <base-select
                                  :value="aMap.type"
                                  :options="types"
                                  :reduce="(item) => item.value"
                                  label="text"
                                  :clearable="false"
                                  disabled
                                />
                              </b-td>
                              <b-td>
                                <base-select
                                  :options="tableNamesItems"
                                  :reduce="(item) => item.value"
                                  :value="aMap.table"
                                  label="text"
                                  :clearable="false"
                                  disabled
                                />
                              </b-td>
                              <b-td class="text-left">
                                <b-form-input
                                  :value="aMap.label"
                                  placeholder="Tên đối tác"
                                  disabled
                                />
                              </b-td>
                              <b-td>
                                <b-form-checkbox
                                  :checked="aMap.require"
                                  :value="1"
                                  :unchecked-value="0"
                                  class="custom-control-primary d-inline-block"
                                  disabled
                                />
                              </b-td>
                              <b-td>
                                <base-button
                                  variant="flat-danger"
                                  class="btn-icon rounded-circle"
                                  @click="handleClickDeleteRowOld(i)"
                                >
                                  <feather-icon size="20" icon="XIcon" />
                                </base-button>
                              </b-td>
                            </b-tr>
                          </template>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </template>
              </b-tab>
              <b-tab title="Các cột giống">
                <template v-if="sameMapping && sameMapping.length > 0">
                  <b-row class="text-center">
                    <b-col md="12" xl="12" class="mb-1">
                      <b-table-simple
                        id="table-mapping3"
                        ref="refUserListTable2"
                        class="position-relative"
                      >
                        <b-thead>
                          <b-tr>
                            <b-th
                              v-for="(header, i) in tableHead"
                              :key="i"
                              :style="`
                             width: ${header.width}%;
                            text-align: ${header.align} !important;
                          `"
                            >
                              {{ header.text }}</b-th
                            >
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <template v-if="mappingLoading">
                            <b-tr>
                              <b-td colspan="100">
                                <b-spinner
                                  variant="primary"
                                  style="width: 3rem; height: 3rem"
                                  class="mr-1"
                                  label="Large Spinner"
                                />
                              </b-td>
                            </b-tr>
                          </template>
                          <template v-else>
                            <b-tr v-for="(aMap, i) in sameMapping" :key="i">
                              <b-td style="padding-left: 20px !important">
                                {{ i + 1 }}
                              </b-td>
                              <b-td class="text-left">
                                <b-form-input :value="aMap.header" size="small" disabled />
                              </b-td>
                              <b-td>
                                <base-select
                                  :value="aMap.type"
                                  :options="types"
                                  :reduce="(item) => item.value"
                                  label="text"
                                  :clearable="false"
                                  disabled
                                />
                              </b-td>
                              <b-td>
                                <base-select
                                  :options="tableNamesItems"
                                  :reduce="(item) => item.value"
                                  :value="aMap.table"
                                  label="text"
                                  :clearable="false"
                                  disabled
                                />
                              </b-td>
                              <b-td class="text-left">
                                <b-form-input
                                  :value="aMap.label"
                                  placeholder="Tên đối tác"
                                  disabled
                                />
                              </b-td>
                              <b-td>
                                <b-form-checkbox
                                  :checked="aMap.require"
                                  :value="1"
                                  :unchecked-value="0"
                                  class="custom-control-primary d-inline-block"
                                  disabled
                                />
                              </b-td>
                            </b-tr>
                          </template>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </template>
              </b-tab>
            </b-tabs>

            <b-row class="text-right">
              <b-col md="12" xl="12" class="mb-1">
                <base-button type="submit" variant="primary">
                  {{ $t('Update') }}
                </base-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTableSimple,
  BTbody,
  BThead,
  BTooltip,
  BTabs,
  BTab,
  BFormRadio,
} from 'bootstrap-vue';
import { BTd, BTh, BTr } from 'bootstrap-vue/src/components/table';
import vSelect from 'vue-select';
import SelectModal from '@/components/base/SelectModal.vue';
import Ripple from 'vue-ripple-directive';
import { partnerList, partnerUpdate, showPartner } from '@/apis/apiPartner';
import constRouter from '@/constants/constRouter';
import { getPerPageToken } from '@/common/localStorage';
import BaseSelect from '@/components/base/BaseSelect.vue';
import { structure, types } from '@/apis/general';
import BaseButton from '@/components/base/BaseButton.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { importUpload } from '@/apis/apiImport';
import { campaignList } from '@/apis/apiCampaign';
import { required } from '@validations';

export default {
  components: {
    BaseButton,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormInput,
    BFormFile,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BCardText,
    BaseSelect,
    BForm,
    BFormCheckbox,
    BFormRadio,
    BThead,
    BTableSimple,
    BTbody,
    BTd,
    BTh,
    BTr,
    BSpinner,
    BTabs,
    BTab,
    SelectModal,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    required,
    constRouter,
    filter: null,

    file: null,
    list: null,
    theLastFileImport: undefined,
    options: {
      page: 1,
      itemsPerPage: 15,
    },
    loading: false,
    isLoadedData: false,

    // upload file
    listFileID: [],
    callingAPI: false,
    fileLoadingBtn: false,

    // template list
    partners: [],
    partnersValue: undefined,
    partnersLoading: false,

    // dialog using to move export view
    dialogMove2ExportView: false,

    // Recursive
    recursive: true,

    // new dataa
    tableHead: [
      {
        text: 'STT',
        width: 1,
      },
      {
        text: 'Tên cột excel',
      },
      {
        text: 'Định dạng',
        width: 14,
      },
      {
        text: 'Đối tượng',
        width: 14,
      },
      {
        text: 'Thuộc tính/ tên gọi khác',
      },
      {
        text: 'Bắt buộc',
        align: 'center',
      },
      {
        text: 'Xóa',
        align: 'center',
      },
    ],

    partnerId: undefined,
    isShowStructure: true,
    workbook: undefined,
    newHeaders: undefined,
    sameHeaders: undefined,
    fileUpload: undefined,
    fileUploading: false,
    startRowSelected: 2,
    header: [],
    columns: [],
    name: undefined,
    code: undefined,
    types: [],
    errOfColumns: [],
    mappingFieldStructure: {},
    tableNames: [],
    tableNamesItems: [],
    table: [],
    mapping: [],
    newMapping: [],
    deleteMapping: [],
    sameMapping: [],
    mappingTemp: [],
    columnsTemp: [],
    headerRowFromFile: [],
    structureLoading: false,
    mappingLoading: false,
    showPartnerLoading: false,
    noInsertNew: false,
    noInsertOld: false,

    // option load file
    dialogOptionLoadingFile: false,
    dialogChoseColumns: false,
    checkedColumns: [],
    checkedAllColumn: false,
    loadingFileSelected: 'remain',
    modalOptionUpdate: false,
    campaignOptions: [],
    campaign: [],
  }),
  watch: {
    checkedAllColumn(isChecked) {
      if (isChecked) {
        this.checkedColumns = this.newHeaders;
      } else if (this.is2ArrEqual(this.checkedColumns, this.newHeaders)) {
        // if 2 array is equal => clear selected
        this.checkedColumns = [];
      }
    },
    checkedColumns(newArr) {
      // check all column
      this.checkedAllColumn = !!this.is2ArrEqual(newArr, this.newHeaders);
    },
  },
  mounted() {
    // start loading
    this.isLoadedData = true;
    this.partnersLoading = true;

    this.options.itemsPerPage = getPerPageToken();

    this.getPartnersAPI();

    // call api data type
    this.getDataType();

    // call api structure
    this.getStructure();

    // call api campaignOptions
    this.fetchCampaigns();
  },
  beforeDestroy() {
    this.recursive = false;
  },
  methods: {
    fetchCampaigns() {
      campaignList()
        .then((res) => {
          this.campaignOptions = res.data.data;
        })
        .catch((err) => {});
    },

    allowRedoImported(list, id) {
      if (list && list.length > 0) {
        // const theFileIndex = _.findKey(list, { id });
        // if (theFileIndex) {
        //   return false;
        // }
      }
      return true;
    },

    getPartnersAPI() {
      partnerList(this.options)
        .then((res) => {
          // turn of loading
          this.partnersLoading = false;
          this.partners = res.data.data
            .filter((partner) => partner.allow_import === 1 || partner.allow_import === true)
            .map((partner) => ({
              value: partner.id,
              text: partner.name,
            }));
          // show file input
          this.showChooseFileView();
        })
        .catch(() => {
          // turn of loading
          this.partnersLoading = false;
        });
    },

    showChooseFileView() {
      const importPartnerId = this.$route.query['import-partner-id'];
      if (importPartnerId !== undefined) {
        this.partnersValue = +importPartnerId;
        document.getElementById('fileUpload').click();
      }
    },

    async uploadFile() {
      if (this.file === null) {
        return;
      }
      // start loading
      this.loading = true;
      this.fileLoadingBtn = true;

      // clear data
      this.mapping = [];
      this.newMapping = [];
      this.deleteMapping = [];
      this.sameMapping = [];
      this.mappingTemp = [];

      await this.readFileCheckCols();

      this.loading = false;
      this.fileLoadingBtn = false;
    },

    async updatePartnerAndUpload() {
      // start loading
      this.modalOptionUpdate = true;
    },

    async readFileCheckCols() {
      await this.showPartner2();

      // read file
      // this.file = this.file[0];
      const workbook = await this.getWorkbookFromFile(this.file);
      if (workbook) {
        this.processDataFromWorkbook(workbook);
      }

      //
      this.handleNewCol();
      this.handleOldCol();
      this.handleSameCol();

      if (this.noInsertOld && this.noInsertNew) {
        // import file
        await this.uploadDataMapping();
      }

      this.mappingLoading = false;
    },

    async showPartner2() {
      const id = +this.partnersValue;
      if (!Number.isNaN(id)) {
        this.partnerId = id;

        // call api show partner
        await showPartner(id)
          .then((res) => {
            const thePartnerRes = res;
            const mapping = thePartnerRes.data.data?.mapping;

            // partner
            this.processPartnerDataBasic4UI(thePartnerRes.data.data);

            if (mapping) {
              // show mapping
              this.isShowStructure = true;
              this.mappingLoading = true;

              // set data for columns in mapping
              const columns = [];
              mapping.forEach((item, index) => {
                columns[index] = this.findColumn(item.table);
              });

              // this.columns = Object.freeze(columns);
              this.columns = columns;
              // turn off loading
              this.structureLoading = false;
            } else {
              // turn off loading
              this.structureLoading = false;
            }
            // set mapping data
            // setTimeout(() => {
            this.setPartnerMapping(mapping);
            // this.mappingLoading = false;
            // }, 500);
          })
          .catch(() => {
            // turn off loading
            this.showPartnerLoading = false;
            this.structureLoading = false;
          });
      }
    },

    setPartnerMapping(mapping) {
      if (mapping) {
        this.mapping = mapping;
      } else {
        this.mapping = [];
      }
    },

    processPartnerDataBasic4UI(data) {
      // turn off showPartnerLoading
      this.showPartnerLoading = false;
      if (data) {
        this.name = data.name;
        this.code = data.code;
        this.startRowSelected = data.start_row;
      }
    },

    handleNewCol() {
      const currentHeaderRow = this.mapping.map((aMap) => this.formatText(aMap.header));
      const loadedHeaderRow = this.headerRowFromFile.map((aMap) => this.formatText(aMap.header));
      this.newHeaders = this.findNewElements(currentHeaderRow, loadedHeaderRow);
      // auto check all columns
      this.checkedAllColumn = true;
      this.checkedColumns = this.newHeaders;

      const listMapAppend = this.checkedColumns?.map(
        (itemCheck) =>
          this.mappingTemp.filter((aMap) => this.formatText(aMap.header) === itemCheck)[0],
      );
      if (listMapAppend) {
        this.insertNewCols(listMapAppend);
      } else {
        this.noInsertNew = true;
      }
    },

    handleOldCol() {
      const currentHeaderRow = this.mapping.map((aMap) => this.formatText(aMap.header));
      const loadedHeaderRow = this.headerRowFromFile.map((aMap) => this.formatText(aMap.header));
      this.newHeaders = this.findNewElements(loadedHeaderRow, currentHeaderRow);
      // auto check all columns
      this.checkedAllColumn = true;
      this.checkedColumns = this.newHeaders;

      const listMapAppend = this.checkedColumns?.map(
        (itemCheck) => this.mapping.filter((aMap) => this.formatText(aMap.header) === itemCheck)[0],
      );
      if (listMapAppend) {
        this.insertOldCols(listMapAppend);
      } else {
        this.noInsertOld = true;
      }
    },

    handleSameCol() {
      const currentHeaderRow = this.mapping.map((aMap) => this.formatText(aMap.header));
      const loadedHeaderRow = this.headerRowFromFile.map((aMap) => this.formatText(aMap.header));
      this.sameHeaders = this.findSameElements(loadedHeaderRow, currentHeaderRow);

      // auto check all columns
      this.checkedAllColumn = true;
      this.checkedColumns = this.sameHeaders;

      const listMapAppend = this.checkedColumns?.map(
        (itemCheck) => this.mapping.filter((aMap) => this.formatText(aMap.header) === itemCheck)[0],
      );
      if (listMapAppend) {
        this.insertSameCols(listMapAppend);
      } else {
        this.noInsertOld = true;
      }
    },

    formatText(text) {
      return text
        .trim()
        .toUpperCase()
        .replace(/\s\s+/g, ' ') // replace multiple spaces with a single space
        .replace(/đ/g, 'd')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },

    is2ArrEqual(arrA, arrB) {
      if (Array.isArray(arrA) && Array.isArray(arrB)) {
        if (arrA.length !== arrB.length) {
          return false;
        }
        return !this.findDiffElements();
      }
      return false;
    },

    findDiffElements(arrA, arrB) {
      if (Array.isArray(arrA) && Array.isArray(arrB)) {
        const temp1 = arrA.filter((item) => arrB.indexOf(item) === -1);
        const temp2 = arrB.filter((item) => arrA.indexOf(item) === -1);
        if (temp1.concat(temp2).length > 0) return temp1.concat(temp2);
        return null;
      }
      return null;
    },

    insertNewCols(listMapAppend) {
      listMapAppend.forEach((aMap) => {
        this.columns.unshift(this.tableNames[0].listCol);
        this.newMapping.unshift(aMap);
      });
    },

    insertOldCols(listMapAppend) {
      listMapAppend.forEach((aMap) => {
        this.columns.unshift(this.tableNames[0].listCol);
        this.deleteMapping.unshift(aMap);
      });
    },

    insertSameCols(listMapAppend) {
      listMapAppend.forEach((aMap) => {
        this.columns.unshift(this.tableNames[0].listCol);
        this.sameMapping.unshift(aMap);
      });
    },

    findNewElements(currentArr, newArr) {
      if (Array.isArray(currentArr) && Array.isArray(newArr)) {
        const result = newArr.filter((item) => currentArr.indexOf(item) === -1);
        if (result.length > 0) return result;
        return null;
      }
      return null;
    },

    findSameElements(currentArr, newArr) {
      if (Array.isArray(currentArr) && Array.isArray(newArr)) {
        const result = newArr.filter((item) => currentArr.indexOf(item) !== -1);
        if (result.length > 0) return result;
        return null;
      }
      return null;
    },

    processStructureData4UI(mappingFieldStructure) {
      this.mappingFieldStructure = mappingFieldStructure;
      const tableNames = [];
      for (let i = 0; i < mappingFieldStructure.length; i += 1) {
        const tableValues = Object.values(mappingFieldStructure[i])[0];
        const tableKeys = Object.keys(mappingFieldStructure[i])[0];
        const cols = [];

        if (tableValues) {
          tableValues.forEach((tableValue) => {
            let colText = this.$t(`${tableKeys}.${tableValue}`);
            if (colText.includes('.')) {
              colText = tableValue;
            }
            // col = { text, value }
            cols.push({
              text: colText,
              value: tableValue,
              id: tableValue,
            });
          });
        }
        // Todo
        // cols = Object.freeze(Utils.sortArObject(cols, 'text'));
        // col = { tableText, tableValue, listCol }
        this.tableNamesItems.push({
          text: this.$t(tableKeys),
          value: tableKeys,
          id: tableKeys,
        });
        tableNames.push({
          text: this.$t(tableKeys),
          id: tableKeys,
          value: tableKeys,
          listCol: cols,
        });
      }
      this.tableNames = Object.freeze(tableNames);
    },

    getWorkbookFromFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        let workbook;
        reader.onload = (e) => {
          const data = e.target.result;
          workbook = this.$xlsx.read(data, { type: 'binary' });
          resolve(workbook);
        };
      });
    },

    processDataFromWorkbook(workbook) {
      this.fileUploading = false;
      this.mappingLoading = false;

      const sheets = workbook.SheetNames;
      const firstSheet = workbook.Sheets[sheets[0]];
      const locHeaderRow = 1;

      const headerRow = this.getHeaderRow(firstSheet, locHeaderRow);
      // const locStartRow = this.startRowSelected;
      // const dataTypes = this.dataTypeOfCols(firstSheet, headerRow, locStartRow);
      // const rowLength = this.$xlsx.utils.sheet_to_json(firstSheet).length;
      // const requires = this.requiredOfCols(firstSheet, headerRow, locStartRow, rowLength);

      this.mappingTemp = [];
      this.columnsTemp = [];
      headerRow.forEach((header, index) => {
        this.columnsTemp[index] = this.tableNames[0].listCol;
        this.mappingTemp.push({
          colExcel: header.colExcel,
          header: header.header,
          label: header.header,
          table: 'customers',
          column: this.columnsTemp[index][0].id,
          type: 'text',
          require: 0,
        });
      });
      this.headerRowFromFile = headerRow;

      // turn of loading
      this.fileUploading = false;
      this.mappingLoading = false;
    },

    // => [{ colExcel: "I", header: "Họ tên"}...]
    getHeaderRow(sheet, positionHeaderRow) {
      // key: ["A2", "B2", "C2",...]
      const keys = Object.keys(sheet)
        .filter((item) => item !== '!margins' && item !== '!ref')
        .filter((item) => {
          // get number from text A1 => temp: ['1']
          const temp = item.match(/\d+/);
          if (Array.isArray(temp)) {
            return +temp.join('') === +positionHeaderRow;
          }
          return false;
        });
      return keys.map((elem) => ({
        colExcel: elem.replace(/[0-9]/g, ''),
        header: `${sheet[elem].v}`,
      }));
    },

    dataTypeOfCols(sheet, headerRow, locStartRow) {
      const colTypeList = [];
      headerRow.forEach((header) => {
        const columnName = header.colExcel;
        // get list key of 1 column
        const listKeyOfACol = Object.keys(sheet).filter((item) => {
          // item null was filtered by library
          const columnNameTemp = item.replace(/[0-9]/g, '');
          return columnNameTemp === columnName;
        });

        const listDataOfACol = listKeyOfACol
          // remove cell header to before
          .filter((item) => {
            const temp = item.match(/\d+/);
            return +temp.join('') >= locStartRow;
          })
          .map((item) => sheet[item]);
        // push new object
        colTypeList.push({
          col: columnName,
          type: this.dataTypeOfCol(listDataOfACol),
        });
      });
      return colTypeList;
    },

    dataTypeOfCol(listCell) {
      if (listCell.length > 0) {
        const temps = listCell.filter((cell) => cell.t === 'n');
        if (listCell.length === temps.length) {
          for (let i = 0; i < listCell.length; i += 1) {
            const cell = listCell[i];
            if (cell.w.includes('/')) {
              return 'date';
            }
          }
          return 'numeric';
        }
        let length = 0;
        for (let i = 0; i < listCell.length; i += 1) {
          const cell = listCell[i];
          const cellValue = cell.v.toString().replace("'", '');
          if (Number.isInteger(+cellValue)) {
            length += 1;
          }
          if (listCell[i].w.includes('/')) {
            const cellTemp = cellValue.replaceAll('/', '');
            if (Number.isInteger(+cellTemp)) {
              return 'date';
            }
          }
        }
        if (listCell.length === length) {
          return 'numeric';
        }
      }
      return 'text';
    },

    requiredOfCols(sheet, headerRow, locStartRow, rowLength) {
      const colRequiredList = [];
      headerRow.forEach((header) => {
        const columnName = header.colExcel;
        // get list key of 1 column
        const listKeyOfACol = Object.keys(sheet).filter((item) => {
          // item null was filtered by library
          const columnNameTemp = item.replace(/[0-9]/g, '');
          return columnNameTemp === columnName;
        });

        const listDataOfACol = listKeyOfACol
          // remove cell header
          .filter((item) => {
            const temp = item.match(/\d+/);
            return +temp.join('') >= locStartRow;
          })
          .map((item) => sheet[item]);
        // push new object
        colRequiredList.push({
          col: columnName,
          require: this.isRequiredColumn(listDataOfACol, locStartRow, rowLength),
        });
      });
      return colRequiredList;
    },

    isRequiredColumn(listCell, locStartRow, rowLength) {
      if (listCell && listCell.length > 0) {
        const temps = listCell.filter((cell) => cell.v !== '');
        if (rowLength - locStartRow + 2 === temps.length) {
          return 1;
        }
        return -1;
      }
      return 0;
    },

    // option method
    scrollTo(
      element,
      options = {
        offset: -500,
        duration: 5000,
      },
    ) {
      setTimeout(() => {
        this.$scrollTo(element, options);
      }, 200);
    },

    handleChangeTableName(tableName, row) {
      this.newMapping[row].table = tableName;
      this.testPerform = Date.now();
      const column = this.findColumn(tableName);
      // const random = Math.floor(Math.random() * 2) + 1;
      // const column = this.tableNames[random].listCol;
      setTimeout(() => {
        this.columns.splice(row, 1, column);
        if (column && column.length > 0) {
          this.$set(this.newMapping[row], 'column', column[0].id);
        } else {
          this.$set(this.newMapping[row], 'column', undefined);
        }
      });
      this.errOfColumns[row] = undefined;
    },

    handleChangeColumn(colName, row) {
      this.newMapping[row].column = colName;
    },

    handleClickDeleteRow(i) {
      this.newMapping.splice(i, 1);
      this.header.splice(i, 1);
      this.columns.splice(i, 1);
    },

    handleClickDeleteRowOld(i) {
      this.deleteMapping.splice(i, 1);
      this.header.splice(i, 1);
      this.columns.splice(i, 1);
    },

    async chooseOptionLoadFile() {
      if (this.noInsertNew && this.loadingFileSelected === 'delete') {
        // import file
        await this.uploadDataMapping();
      } else {
        await this.validateAndCallApiSubmit();
      }
    },

    async uploadDataMapping() {
      // start loading
      this.loading = true;
      this.fileLoadingBtn = true;

      // check validate
      const result = await this.$refs.uploadForm.validate();
      if (result && this.file) {
        const inputFiles = this.file;
        // call api upload
        importUpload(inputFiles, this.partnersValue, this.campaign)
          .then((res) => {
            // stop loading button
            this.fileLoadingBtn = false;

            this.$router.push({
              name: constRouter.IMPORT.name,
            });

            // show Toast
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('Upload {resource} success', {
                  resource: this.$t('file'),
                }),
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: this.$t('Upload {resource} success', {
                  resource: this.$t('file'),
                }),
              },
            });
          })
          .catch((err) => {
            if (err.response?.status === 422) {
              // show Toast
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Upload {resource} failed', {
                    resource: this.$t('file'),
                  }),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: this.$t('Missing data input'),
                },
              });
            } else {
              let errMessage = err.data.message;
              const errDetails = err.data.errors?.importFile[0];
              if (errDetails) {
                errMessage = errDetails;
              }
              // show Toast
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Upload {resource} failed', {
                    resource: this.$t('file'),
                  }),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: this.$t(errMessage),
                },
              });
            }
          })
          .then(() => {
            // stop loading button
            this.fileLoadingBtn = false;
            this.loading = false;
          });
      } else {
        // stop loading button
        this.fileLoadingBtn = false;
        this.loading = false;
      }
    },

    getMapping() {
      const mapping = [...this.mapping];
      let { newMapping } = this;
      const { deleteMapping } = this;
      newMapping = newMapping.map((item, index) => ({
        ...item,
      }));
      let result = [...newMapping, ...mapping];
      if (this.loadingFileSelected === 'delete') {
        result = result.filter((aMap) => {
          const found = deleteMapping.find((deleteMap) => deleteMap.header === aMap.header);
          return !found;
        });
      }
      return result;
    },

    getMappingNameForm() {
      const mapLength = this.newMapping.length;
      const myForm = this.$refs.mappingFormName;
      const formData = new FormData(myForm);
      const array = Array.from(formData);
      const object = array.map((item) => {
        const key = item[0];
        const value = item[1];
        return {
          [key]: value,
        };
      });

      const mapping = [];
      for (let i = 0; i < mapLength; i += 1) {
        mapping[i] = {};
        for (let j = 0; j < object.length; j += 1) {
          const item = object[j];
          if (item[`mapping:${i}:type`]) {
            mapping[i].type = item[`mapping:${i}:type`];
          }
          if (item[`mapping:${i}:column`]) {
            mapping[i].column = item[`mapping:${i}:column`];
          }
          if (item[`mapping:${i}:table`]) {
            mapping[i].table = item[`mapping:${i}:table`];
          }
          if (item[`mapping:${i}:require`]) {
            mapping[i].require = item[`mapping:${i}:require`];
          }
        }
      }

      return mapping;
    },

    processData4API(name, code, mapping, startRow) {
      let dataSend;
      if (this.isShowStructure) {
        // add sub field
        const mappingSend = this.addSubField2API(mapping);
        dataSend = {
          name,
          code,
          startRow,
          mapping: mappingSend,
        };
      } else {
        dataSend = {
          name,
          code,
          startRow,
        };
      }
      return dataSend;
    },

    addSubField2API(mapping) {
      const data = mapping;
      // loop arr mapping, each element compare to the structure
      data.forEach((item, index) => {
        // => { customers: [ "code", "name", "birthday", "phone", "address"] }
        const object = this.mappingFieldStructure.find(
          (tableSelected) => Object.keys(tableSelected)[0] === item.table,
        );
        // if values array of temp <= 0 => sub
        if (this.isSub(object)) {
          data[index].sub = 1;
          delete data[index].column;
        } else {
          // sub = 0, means has column
          data[index].sub = 0;
        }

        // if label = undefined, label = header
        if (!data[index].label) {
          data[index].label = data[index].header;
        }
      });
      return data;
    },

    // check which table name is sub?
    isSub(table) {
      // if undefined
      if (!table) {
        return true;
      }
      // if values of table <= 0: => sub
      return !(Object.values(table)[0] && Object.values(table)[0].length > 0);
    },

    async validateAndCallApiSubmit() {
      const mappingFormObserver = this.$refs.mappingForm;
      return mappingFormObserver.validate().then((isValid) => {
        if (isValid) {
          // valid
          // list err of fields
          const errAvailable = this.errOfColumns.filter((item) => item !== undefined);

          if (errAvailable.length <= 0) {
            const { name, code, startRowSelected: startRow } = this;
            const mapping = this.getMapping();

            const dataSend = this.processData4API(name, code, mapping, startRow);
            partnerUpdate(this.partnerId, dataSend)
              .then(() => {
                // turn of loading
                this.loading = false;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Update {resource}', {
                      resource: this.$t('partner'),
                    }),
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: this.$t('Update {resource} successfully!', {
                      resource: this.$t('partner'),
                    }),
                  },
                });
                this.uploadDataMapping();
              })
              .catch((err) => {
                // turn of loading
                this.loading = false;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Update {resource}', {
                      resource: this.$t('partner'),
                    }),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: this.$t('Update {resource} failed!', {
                      resource: this.$t('partner'),
                    }),
                  },
                });
                // set err
                if (err && err.data.errors) {
                  // set errors from api for vee validate errors
                  mappingFormObserver.setErrors(err.data.errors);
                  this.scrollToError(err.data.errors, mappingFormObserver);
                }
              });
          } else {
            // turn of loading
            this.loading = false;
          }
        } else {
          // turn of loading
          this.loading = false;
          this.scrollToError(mappingFormObserver.errors, mappingFormObserver);
        }
      });
    },

    cancelLoadNewFile() {
      // turn of loading;
      this.dialogOptionLoadingFile = false;
      this.dialogChoseColumns = false;
      this.fileUploading = false;
      this.mappingLoading = false;
    },

    handleAddNewRow() {
      const index = this.newMapping.length;
      this.columns[index] = this.tableNames[0].listCol;
      this.newMapping.push({
        header: undefined,
        label: undefined,
        table: 'customers',
        column: this.columns[index][0].id,
        type: 'text',
        require: 0,
      });

      // scroll down
      this.scrollTo('#add-new-row', {
        offset: -100,
        duration: 5000,
      });
    },

    // api data type
    getDataType() {
      // Api to get types
      types()
        .then((res) => {
          // process data type for ui
          this.types = this.processDataTypes4UI(res.data);
        })
        .catch(() => {});
    },

    // import excel header and get structure data from API
    async getStructure() {
      // Api to get structure
      await structure()
        .then((res) => {
          this.processStructureData4UI(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    findColumn(tableName) {
      // edit
      const loc = this.tableNames.findIndex((item) => item.value === tableName);
      if (loc !== -1) {
        return this.tableNames[loc].listCol;
      }
      return undefined;
    },

    processDataTypes4UI(oldTypes) {
      const typeList = [];
      oldTypes.forEach((type) => {
        const typeText = this.$t(type);
        switch (type) {
          case 'text': {
            typeList.push({
              value: type,
              text: typeText,
              top: 1,
            });
            break;
          }
          case 'numeric': {
            typeList.push({
              value: type,
              text: typeText,
              top: 2,
            });
            break;
          }
          case 'date': {
            typeList.push({
              value: type,
              text: typeText,
              top: 3,
            });
            break;
          }
          case 'phone': {
            typeList.push({
              value: type,
              text: typeText,
              top: 4,
            });
            break;
          }
          case 'address': {
            typeList.push({
              value: type,
              text: typeText,
              top: 5,
            });
            break;
          }
          default: {
            typeList.push({
              value: type,
              text: typeText,
              top: 9999,
            });
            break;
          }
        }
      });
      // Todo
      // typeList = Utils.sortArObject(typeList, 'top');
      return typeList;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
